import axiosInstance from '../../axios';
import ServerRoutes from './routes/index';
import { participantsArraySchema } from './schemas/chat-schemas';
import { errorSchema, tokenSchema } from './schemas/common-schemas';
import { validate } from './schemas/validator';

const getTwilioToken = async () => {
    try {
        let url = ServerRoutes.getTwilioToken;

        const { status, data, error } = await axiosInstance.get(url);
        const { result, e, s } = await validate(tokenSchema, data, false);
        return { data: result, status: s ? s : status, error: e ? e : error };
    }
    catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (e.response.status) {
            case 404:
                message = 'Could not get twilio token';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
}

const getParticipants = async () => {
    try {
        let url = ServerRoutes.chatParticipants + '?limit=100';

        const { status, data, error } = await axiosInstance.get(url);
        const { result, e, s } = await validate(participantsArraySchema, data, false);
        return { data: result, status: s ? s : status, error: e ? e : error };
    }
    catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (e.response.status) {
            case 404:
                message = 'Could not get twilio token';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
}

const conversationsApi = {
    getTwilioToken: getTwilioToken,
    getParticipants: getParticipants
}

export default conversationsApi;