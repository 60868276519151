import React from 'react';
import DashboardNavigation from '../components/Navigation/DashboardNavigation';
import styled from 'styled-components';

const DashboardLayoutContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
        height: auto;
    }
`;

const DashboardLayout = ({ children }) => {
    return (
        <DashboardLayoutContainer>
            <DashboardNavigation />
            {children}
        </DashboardLayoutContainer>
    );
};

export default DashboardLayout;
