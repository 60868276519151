import { createSlice } from '@reduxjs/toolkit';
import teamsApi from '../../api/teams-api';

const initialState = {
    pro: null,
    error: null,
    loading: false,
};

const _loadStart = (state, action) => {
    state.loading = true;
    state.error = null;
};

const _loadFailed = (state, action) => {
    const error = action.payload;
    state.loading = false;
    state.error = error;
};

const _loadMemberSuccess = (state, action) => {
    const data = action.payload;
    state.loading = false;
    state.pro = data;
};

const _cleanPro = (state) => {
    return initialState;
};

const pro = createSlice({
    name: 'pro',
    initialState: initialState,
    reducers: {
        loadStart: _loadStart,
        loadFailed: _loadFailed,
        loadMemberSuccess: _loadMemberSuccess,
        cleanPro: _cleanPro,
    },
});

const { actions, reducer } = pro;

export const { loadStart, loadFailed, loadMemberSuccess, cleanPro } = actions;

export default reducer;

export const loadMember = (proId) => {
    return async (dispatch) => {
        dispatch(loadStart());
        const { status, data, error } = await teamsApi.fetchMember(proId);
        if (status === 200) {
            return dispatch(loadMemberSuccess(data));
        } else {
            return dispatch(loadFailed(error));
        }
    };
};
