import { createSlice } from '@reduxjs/toolkit';
import conversationsApi from '../../api/conversations-api';
import twilioClient from '../../../twilio-client';

const initialState = {
    conversations: null,
    participants: [],
    twilioConnected: false,
    conversationsError: null,
    converstaionsLoading: false,
    participantsLoading: false,
    participantsError: null,
    currentChatSid: null,
    version: null,
};

const _loadStart = (state, action) => {
    state.converstaionsLoading = true;
    state.conversationsError = null;
};

const _loadFailed = (state, action) => {
    const error = action.payload;
    state.converstaionsLoading = false;
    state.conversationsError = error;
};

const _loadParticipantsStart = (state, action) => {
    state.converstaionsLoading = true;
    state.conversationsError = null;
};

const _loadParticipantsFailed = (state, action) => {
    const error = action.payload;
    state.converstaionsLoading = false;
    state.conversationsError = error;
};

const _loadConversationsSuccess = (state, action) => {
    const data = action.payload;
    state.converstaionsLoading = false;
    state.conversations = data;
};

const _loadParticipantsSuccess = (state, action) => {
    const { data } = action.payload;
    state.participantsLoading = false;
    state.participants = data;
}

const _cleanChats = (state) => {
    state.conversations = null;
    state.participants = [];
    state.conversationsError = null;
    state.converstaionsLoading = false;
    state.participantsLoading = false;
    state.participantsError = null;
    state.currentChatSid = null;
    state.version = null;
};

const _addUnreadMessage = (state, action) => {
    const conversationSid = action.payload;
    if (conversationSid !== state.currentChatSid && state.conversations && state.conversations[conversationSid]) {
        state.conversations[conversationSid].unreadMessagesCount = state.conversations[conversationSid].unreadMessagesCount + 1;
        state.version = new Date().getTime();
    }
}

const _disconnectTwilio = (state, action) => {
    state.twilioConnected = false;
}

const _cleanUnreadMessages = (state, action) => {
    const conversationSid = action.payload;
    if (state.conversations && state.conversations[conversationSid]) {
        state.conversations[conversationSid].unreadMessagesCount = 0;
    }
}

const _connectTwilio = (state) => {
    state.twilioConnected = true;
}

const _setCurrentChatSid = (state, action) => {
    const conversationSid = action.payload;
    state.currentChatSid = conversationSid;
}

const conversations = createSlice({
    name: 'conversations',
    initialState: initialState,
    reducers: {
        loadStart: _loadStart,
        loadFailed: _loadFailed,
        loadParticipantsStart: _loadParticipantsStart,
        loadParticipantsFailed: _loadParticipantsFailed,
        loadConversationsSuccess: _loadConversationsSuccess,
        loadParticipantsSuccess: _loadParticipantsSuccess,
        cleanUnreadMessages: _cleanUnreadMessages,
        cleanChats: _cleanChats,
        disconnectTwilio: _disconnectTwilio,
        addUnreadMessage: _addUnreadMessage,
        connectTwilio: _connectTwilio,
        setCurrentChatSid: _setCurrentChatSid
    },
});

const { actions, reducer } = conversations;

export const { loadStart, loadFailed, loadParticipantsStart,
    loadParticipantsFailed, loadConversationsSuccess,
    loadParticipantsSuccess, connectTwilio, cleanChats,
    cleanUnreadMessages, addUnreadMessage, disconnectTwilio, setCurrentChatSid } = actions;

export default reducer;

export const getTwilioToken = async () => {
    let { data } = await conversationsApi.getTwilioToken();
    return data.jwt;
}

export const loadConversations = () => {
    return async (dispatch) => {
        dispatch(loadStart());
        let conversations = await twilioClient.client.getSubscribedConversations();
        let conversationsObj = {};
        if (conversations) {
            for (let i = 0; i < conversations.items.length; i++) {
                let unreadMessagesCount = await conversations.items[i].getUnreadMessagesCount();
                let conversationSid = conversations.items[i].sid;
                conversationsObj[conversationSid] = conversations.items[i];
                conversationsObj[conversationSid].unreadMessagesCount = unreadMessagesCount;
            }
            return dispatch(loadConversationsSuccess(conversationsObj));
        } else {
            return dispatch(loadFailed('Could not load conversations'));
        }
    };
};

export const loadParticipants = () => {
    return async (dispatch) => {
        dispatch(loadParticipantsStart());
        const { status, data, error } = await conversationsApi.getParticipants();
        if (status === 200) {
            return dispatch(loadParticipantsSuccess(data));
        } else {
            return dispatch(loadParticipantsFailed(error));
        }
    };
};