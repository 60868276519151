import { createSlice } from '@reduxjs/toolkit';
import { cleanUnreadMessages } from '../conversations/conversations-reducer';

const initialState = {
    messages: [],
    totalMessages: 0,
    error: null,
    loading: false,
    messageLoading: false,
    messageError: null,
    hasPrevPage: true,
};

const _loadStart = (state, action) => {
    state.loading = true;
    state.error = null;
};

const _loadMessageStart = (state, action) => {
    state.messageLoading = true;
    state.messageError = null;
};

const _loadFailed = (state, action) => {
    const error = action.payload;
    state.loading = false;
    state.error = error;
};

const _loadMessageFailed = (state, action) => {
    const error = action.payload;
    state.messageLoading = false;
    state.messageError = error;
}

const _loadMessagesSuccess = (state, action) => {
    const { messages, totalMessages, hasPrevPage } = action.payload;
    state.loading = false;
    state.messages = messages;
    state.totalMessages = totalMessages;
    state.hasPrevPage = hasPrevPage;
};

const _loadMoreMessageSuccess = (state, action) => {
    const { message } = action.payload;
    state.messageLoading = false;
    state.messages = [...state.messages, message];
}

const _loadPreviousMessagesSuccess = (state, action) => {
    const { messages, hasPrevPage } = action.payload;
    state.loading = false;
    state.messages = [...messages, ...state.messages];
    state.hasPrevPage = hasPrevPage;
}

const _cleanMessages = (state) => {
    return initialState;
};

const chat = createSlice({
    name: 'chat',
    initialState: initialState,
    reducers: {
        loadStart: _loadStart,
        loadMessageStart: _loadMessageStart,
        loadFailed: _loadFailed,
        loadMessageFailed: _loadMessageFailed,
        loadMessagesSuccess: _loadMessagesSuccess,
        cleanMessages: _cleanMessages,
        loadMoreMessageSuccess: _loadMoreMessageSuccess,
        loadPreviousMessagesSuccess: _loadPreviousMessagesSuccess
    },
});

const { actions, reducer } = chat;

export const { loadStart, loadFailed, loadMessageStart, loadMessagesSuccess, cleanMessages, loadMessageFailed, loadMoreMessageSuccess, loadPreviousMessagesSuccess } = actions;

export default reducer;

export const loadMessages = (chat) => {
    return async (dispatch) => {
        try {
            dispatch(loadStart());
            let messages = await chat.getMessages(30);
            let totalMessages = await chat.getMessagesCount();
            if (messages && messages.items) {
                if (messages.items.length > 0) {
                    chat.updateLastReadMessageIndex(messages.items[messages.items.length - 1].state.index);
                    dispatch(cleanUnreadMessages(chat.sid));
                }
                return dispatch(loadMessagesSuccess({
                    messages: messages.items.map((message) => {
                        return message.state;
                    }),
                    totalMessages: totalMessages,
                    hasPrevPage: messages.hasPrevPage
                }));
            } else {
                return dispatch(loadFailed('Could not get messages'));
            }
        } catch (err) {
            return dispatch(loadFailed(err.message));
        }
    };
};

export const onMessageAdd = (message) => {
    return async (dispatch) => {
        try {
            dispatch(loadMessageStart());
            if (message) {
                return dispatch(loadMoreMessageSuccess({
                    message: message
                }));
            } else {
                return dispatch(loadFailed('Could not get message'));
            }

        } catch (err) {
            return dispatch(loadFailed(err.message));
        }
    };
}

export const loadPreviousMessages = (chat) => {
    return async (dispatch, getState) => {
        let currentState = getState().chat;
        let lastMessageIndex = currentState?.messages[0].index - 1;
        if (lastMessageIndex < 0) {
            return;
        }
        dispatch(loadStart());
        let chatMessages = await chat.getMessages(30, lastMessageIndex, 'backwards');
        if (chatMessages && chatMessages.items) {
            return dispatch(loadPreviousMessagesSuccess({
                messages: chatMessages.items.map((message) => {
                    return message.state;
                }),
                hasPrevPage: chatMessages.hasPrevPage
            }));
        } else {
            return dispatch(loadFailed('Could not get messages'));
        }
    };
}