import React from 'react';
import { FlexRowSpaced } from '../../../../common/components/CommonStyledComponent';
import { PopupContainer, PageTitle, PicturesContainer, Picture } from '../styles/PicturesPopupStyles';
import env from '../../../../config';

import { ReactComponent as LeftArrowIcon } from '../../../../assets/icons/left-arrow.svg';

const PicturesPopup = ({ onBackClick, name, pictures }) => {
    return (
        <PopupContainer>
            <FlexRowSpaced>
                <LeftArrowIcon
                    width={20}
                    height={18}
                    fill={'#22222'}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        onBackClick();
                    }}
                />
                <PageTitle>{name + ' Pictures'}</PageTitle>
                <div></div>
            </FlexRowSpaced>
            <PicturesContainer>
                {pictures &&
                    pictures.map((picture, index) => {
                        return <Picture key={index} alt='' src={env.apiGateway.PROS_BUCKET_URL + picture}></Picture>;
                    })}
            </PicturesContainer>
        </PopupContainer>
    );
};

export default PicturesPopup;
