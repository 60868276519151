const dev = {
	apiGateway: {
		API_SERVER_URL: "https://api.eventro.ivtech.co.il/",
		BUCKET_URL: "https://dev-eventro-static-media.s3.eu-central-1.amazonaws.com/",
		PROS_BUCKET_URL: "https://dev-eventro-pros-files.s3.eu-central-1.amazonaws.com/"
		// API_SERVER_URL: "https://localhost:8080"
	}
};

const int = {
	apiGateway: {
		API_SERVER_URL: "https://api.eventro.ivtech.co.il/",
		BUCKET_URL: "https://dev-eventro-static-media.s3.eu-central-1.amazonaws.com/",
		PROS_BUCKET_URL: "https://dev-eventro-pros-files.s3.eu-central-1.amazonaws.com/"
	}
};

const prod = {
	apiGateway: {
		API_SERVER_URL: "https://api.eventro.ivtech.co.il/",
		BUCKET_URL: "https://dev-eventro-static-media.s3.eu-central-1.amazonaws.com/",
		PROS_BUCKET_URL: "https://dev-eventro-pros-files.s3.eu-central-1.amazonaws.com/"
	}
};

let config = dev;

if (process.env.REACT_APP_STAGE.trim() === 'production') {
	config = prod;
}
else if (process.env.REACT_APP_STAGE.trim() === 'int') {
	config = int;
}

const env = {
	APP_VERSION: 0.1,
	// Add common config values here
	...config
}

export default env;