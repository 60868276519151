import React from 'react';
import styled from 'styled-components';
import { initialsByName } from '../utils/utils';

export const RoundNameLogo = styled.div`
	width: ${props => props.width + 'px'};
	height: ${props => props.height + 'px'};
	background: ${props => props.bg ? props.bg : '#bbbbbb'};
	border-radius: ${props => (props.radius ? props.radius : (props.width / 2)) + 'px'};
	align-items: center;
	display: flex;
	justify-content: center;
	color: ${props => props.color ? props.color : 'white'};
	font-family: ${props => props.fontFamily ? props.fontFamily : 'InterUI-SemiBold'}; 
	font-size: ${props => props.fontSize ? props.fontSize : 16 + 'px'};
	line-height: 16px;
	user-select: none;
	box-sizing: border-box;
`;

export const RoundImage = styled.img`
	width: ${props => props.width + 'px'};
	height: ${props => props.height + 'px'};
	border-radius: ${props => (props.radius ? props.radius : (props.width / 2)) + 'px'};
`;


const ImageContainer = styled.div`
	height: ${props => props.height + 'px'};
	width: ${props => props.width + 'px'};
	display:inline-block;
	overflow: hidden;
	border-radius:${props => props.withButton ? (props.radius ? props.radius : (props.width / 2)) + 'px' : null};
	border: none;
	position: relative;
	padding: 0;
;`


const RoundProfileImage = React.memo(({ ...props }) => {

    let image = props.imgUrl ?
        <ImageContainer {...props}>
            <RoundImage src={props.imgUrl} {...props} />
        </ImageContainer>
        :
        <ImageContainer {...props}>
            <RoundNameLogo {...props}>{initialsByName(props.name)}</RoundNameLogo>
        </ImageContainer>
        ;

    return image;
})

export default RoundProfileImage;