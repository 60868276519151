import React from 'react';

import { ChatAvatar, ChatBubble, ChatMessageContainer } from '../MessagesStyles';
import { ReactComponent as SuccessStar } from '../../../../assets/icons/success-star.svg';
import env from '../../../../config';
import RoundProfileImage from '../../../../common/components/RoundProfileImage';

const ChatMessage = ({ avatar, message, name, isSucessManager, isMe }) => {
    return (
        <ChatMessageContainer isMe={isMe}>
            {!isMe ?
                <div style={{ position: 'relative', width: '52px', marginRight: '30px' }}>
                    {avatar ?
                        <ChatAvatar style={{ marginRight: '0px' }} src={env.apiGateway.PROS_BUCKET_URL + avatar} alt="/" />
                        : <RoundProfileImage width={52} height={52} name={name} />}
                    {isSucessManager ? <SuccessStar width={20} height={20} style={{ position: 'absolute', top: '-5px', right: '-5px' }} /> : null}
                </div>

                : null
            }
            <ChatBubble isMe={isMe}>
                {message}
            </ChatBubble>
        </ChatMessageContainer>
    )
}

export default ChatMessage;