
export const validate = async (schema, data, forceCast = true) => {
    try {
        await schema.validate(data, { strict: true });
    }
    catch (e) {
        console.error(e);
        if (!forceCast) {
            return { e: 'Data does not match schema', s: 400 };
        }
    }
    const result = schema.cast(data);
    return { result: result };
}