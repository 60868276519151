import React from 'react';
import { FlexRowAligned } from '../../../../common/components/CommonStyledComponent';
import { ReactComponent as FacebookIcon } from '../../../../assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../../../assets/icons/instagram.svg';
import { ReactComponent as TiktokIcon } from '../../../../assets/icons/tiktok.svg';
import { ReactComponent as TwitterIcon } from '../../../../assets/icons/twitter.svg';

const ProTabSocial = ({ pro }) => {
    return (
        <FlexRowAligned style={{ margin: '50px 0' }}>
            {pro.facebook ? (
                <a href={pro.facebook}>
                    <FacebookIcon width={'18'} height={'18'} fill={'#222222'} style={{ marginRight: '50px' }} />
                </a>
            ) : null}
            {pro.tiktok ? (
                <a href={pro.tiktok}>
                    <TiktokIcon width={'18'} height={'18'} fill={'#222222'} style={{ marginRight: '50px' }} />
                </a>
            ) : null}
            {pro.instagram ? (
                <a href={pro.instagram}>
                    <InstagramIcon width={'18'} height={'18'} fill={'#222222'} style={{ marginRight: '50px' }} />
                </a>
            ) : null}
            {pro.twitter ? (
                <a href={pro.twitter}>
                    <TwitterIcon width={'18'} height={'18'} fill={'#222222'} style={{ marginRight: '50px' }} />
                </a>
            ) : null}
        </FlexRowAligned>
    );
};

export default ProTabSocial;
