import moment from 'moment-timezone';

const messageDispatch = (time, isPast, type) => {
	const message = {
		past: {
			minutes: [`${time} minute ago`, `${time} minutes ago`],
			hours: [`${time} hour ago`, `${time} hours ago`],
			day: [`1 day ago`]
		},
		future: {
			minutes: [`In ${time} minute`, `In ${time} minutes`],
			hours: [`In ${time} hour`, `In ${time} hours`],
			day: [`In 1 day`]
		}
	}

	if(isPast){
		return message.past[type];
	}
	else {
		return message.future[type];
	}
}

const returnPositiveNumber = (num, isNegative) => {
	if(isNegative){
		return num = num * -1;
	}
	return num;
} 

const returnFutureOrPastTime = (diff, now, tzDate, isPast) => {

	diff = returnPositiveNumber(diff, !isPast);
	if (diff >= 0 && diff < 60) {
		diff = diff === 0 ? 1 : diff;
		return diff === 1 ? messageDispatch(diff, isPast, 'minutes')[0] : messageDispatch(diff, isPast, 'minutes')[1];
	}
	diff = now.diff(tzDate, 'hours');
	diff = returnPositiveNumber(diff, !isPast);
	if (diff >= 0 && diff < 24) {
		diff = diff === 0 ? 1 : diff;
		return diff === 1 ? messageDispatch(diff, isPast, 'hours')[0] : messageDispatch(diff, isPast, 'hours')[1];
	}
	diff = now.diff(tzDate, 'days');
	diff = returnPositiveNumber(diff, !isPast);
	if (diff > 0 && diff < 2) {
		return messageDispatch(diff, isPast, 'day')[0];
	}

	return null;
}

export const formatLastUpdate = (date, inMillis = false, timezone="Europe/London", is24hformat = false, dateFormat='D/M/Y') => {
	if(!date){
		return null;
	}
	if( inMillis ){
		date = new Date(Number(date));
	}
	let formatedDate = "";
	const tzDate = moment.tz(new Date(date), timezone);
	const now = moment.tz(timezone);
	let diff = now.diff(tzDate, 'minutes');
	let isPast = tzDate < now;

	formatedDate = returnFutureOrPastTime(diff, now, tzDate, isPast);
	if(formatedDate){
		return formatedDate;
	}

	let hourFormat = is24hformat ? 'HH:mm' : 'hh:mm a';
	formatedDate = tzDate.format(hourFormat + ', ' + dateFormat);
	return formatedDate;
}

export const ShortFormatUpdate = (date, timezone="Europe/London", is24hformat = false, dateFormat='D/M/Y') => {
	const tzDate = moment.tz(new Date(date), timezone);
	const now = moment.tz(timezone);
	let hourFormat = is24hformat ? 'HH:mm' : 'hh:mm a';
	let diff = now.diff(tzDate, 'minutes');
	if (diff >= 0 && diff < 60) {
		diff = diff === 0 ? 1 : diff;
		return diff === 1 ? diff + 'm ago' : diff + 'm ago';
	}
	diff = now.diff(tzDate, 'hours');
	if (diff >= 0 && diff < 24) {
		diff = diff === 0 ? 1 : diff;
		return diff === 1 ? diff + 'h ago' : diff + 'h ago';
	}
	if (diff > 0) {
		return tzDate.format(dateFormat);
	}
	else {
		return tzDate.format(hourFormat);
	}
}

export const commentPostDate = (date, timezone="Europe/London", is24hformat = false, dateFormat='D/M/Y') => {
	const tzDate = moment.tz(new Date(date), timezone);
	const now = moment.tz(timezone);
	let diff = now.diff(tzDate, 'minutes');
	if (diff >= 0 && diff < 60) {
		diff = diff === 0 ? 1 : diff;
		return diff === 1 ? diff + ' minute ago' : diff + ' minutes ago';
	}
	diff = now.diff(tzDate, 'hours');
	if (diff >= 0 && diff < 24) {
		diff = diff === 0 ? 1 : diff;
		return diff === 1 ? diff + ' hour ago' : diff + ' hours ago';
	}
	diff = now.diff(tzDate, 'days');
	if (diff > 0 && diff < 8) {
		return diff === 1 ? 1 + ' day ago' : diff + ' days ago';
	}

	diff = now.diff(tzDate, 'weeks');
	if (diff > 0 && diff < 2) {
		return 'a week ago';
	}

	let hourFormat = is24hformat ? 'HH:mm' : 'hh:mm a';
	const formatedDate = tzDate.format(hourFormat + ', ' + dateFormat);
	return formatedDate;
}


export const formatDateTime = (date, timezone="Europe/London", is24hformat = false, dateFormat='D/M/Y') => {
	const tzDate = moment.tz(new Date(date), timezone);
	let hourFormat = is24hformat ? 'HH:mm' : 'hh:mm A';
	const formatedDate = tzDate.format(hourFormat + ' ' + dateFormat, timezone);
	return formatedDate;
}

export const formatDate= (date, timezone="Europe/London", dateFormat='D/M/Y') => {
	const tzDate = moment.tz(new Date(date), timezone);
	const formatedDate = tzDate.format(dateFormat, timezone);
	return formatedDate;
}

export const formatTime = (date, timezone="Europe/London", is24hformat = false) => {
	const tzDate = moment.tz(new Date(date), timezone);
	let hourFormat = is24hformat ? 'HH:mm' : 'hh:mm A';
	const formatedDate = tzDate.format(hourFormat);
	return formatedDate;
}

export const datePickerFormat = (format) => {
	if (format === 'D/M/Y') return 'dd-MM-yyyy';
	if (format === 'M/D/Y') return 'MM-dd-yyyy';
	return 'yyyy-MM-dd';
}

export const getDateRange = (startDate, endDate) =>{
	const start = new Date(startDate);
	const end = new Date(endDate);
	const today = new Date();
	if( start.toDateString() === end.toDateString() && start.toDateString() === today.toDateString()){
		return 'Today';
	} else {
		const str = moment(start).format("DD/MM/YYYY") +  ' - ' + moment(end).format("DD/MM/YYYY");
		return str;
	}
}

export const getNextDate = (lastRun, reportType, timezone="Europe/London", is24hformat = false, dateFormat='D/M/Y') => {
	const tzDate = moment.tz(lastRun, timezone).add(1, 'day');
	switch(reportType){
		case 'daily':  return formatDateTime(tzDate, timezone, is24hformat, dateFormat);
		case 'monthly':  return formatDateTime(tzDate, timezone, is24hformat, dateFormat);
		case 'weekly':  return formatDateTime(tzDate, timezone, is24hformat, dateFormat);
		default: return formatDateTime(tzDate, timezone, is24hformat, dateFormat)

	}
}

export const calculateDuration = (duration) => {
	let time = duration/1000;
	let result = '';
	if (time/(3600 * 24 * 30) >= 1) {
		const t = (time/(3600 * 24 * 30)).toFixed(0);
		result = t + ' ' + (t > 1 ? 'months' : 'month');
		time = time - t * (3600 * 24 * 30);
	}
	if (time/(3600 * 24) >= 1) {
		const t = (time/(3600 * 24)).toFixed(0);
		result += ' ' + t + ' ' + (t > 1 ? 'days' : 'day');
		time = time - t * (3600 * 24);
	}
	if (time/(3600) >= 1) {
		const t = (time/(3600)).toFixed(0);
		result += ' ' + t + ' ' + (t > 1 ? 'hours' : 'hour');
		time = time - t * (3600);
	}
	if (time/(60) >= 1) {
		const t = (time/(60)).toFixed(0);
		result += ' ' + t + ' ' + (t > 1 ? 'minutes' : 'minute');
		time = time - t * (60);
	}

	return result;
}

export const getTimezone = () => {
	return moment.tz.guess();
}