import styled from 'styled-components';

export const PageTitle = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: var(--black);
`;

export const PopupContainer = styled.div`
    width: 100%;
    min-height: 100%;
    height: max-content;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1200;
    background-color: #fff;
    box-sizing: border-box;
    padding: 50px;

    @media (max-width: 768px) {
        padding: 20px;
    }
`;

export const PicturesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
    width: 100%;
    padding: 40px 60px;
    box-sizing: border-box;

    @media (max-width: 800px) {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 30px;
        padding: 60px 0;
    }
`;

export const Picture = styled.img`
    width: 100% !important;
    height: auto !important;
    border-radius: 16px;
    object-position: center;
`;
