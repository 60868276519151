import React from 'react';
import { FlexRow } from '../../../../common/components/CommonStyledComponent';
import { formatDate } from '../../../../common/utils/date-utils';
import env from '../../../../config';
import { Bubble, ChatDate, ChatInfo, ChatName, ChatIcons, ChatAvatar, UnreadCount } from '../MessagesStyles';

const ConversationBubble = ({ chat, participants, selected, click, unreadMessages }) => {
    return (
        <FlexRow style={{ alignItems: 'center', width: '100%' }}>
            <Bubble selected={selected} onClick={() => click(chat.sid, participants)}>
                <ChatIcons>
                    {
                        participants?.participants.slice(0, 5).map((participant, index) => {
                            if (participant.pro.avatar) {
                                return <ChatAvatar style={{ zIndex: 10 - index }} key={index} src={env.apiGateway.PROS_BUCKET_URL + participant?.pro?.avatar} alt="/" />
                            } else {
                                return null;
                            }
                        })
                    }
                </ChatIcons>
                <ChatInfo>
                    <ChatDate>{formatDate(chat.channelState.dateUpdated, "Asia/Jerusalem")}</ChatDate>
                    <ChatName>{chat.channelState.friendlyName}</ChatName>
                </ChatInfo>
            </Bubble>
            <UnreadCount>{unreadMessages !== 0 ? unreadMessages : null}</UnreadCount>
        </FlexRow>
    )
}

export default React.memo(ConversationBubble);